<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2023-11-19 13:36:07
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-06-13 10:32:22
-->
<template>
  <div class="containerBlock" ref="topicScrollTop">
    <div class="listWrap listDrag" ref="listDragref" :list="topicList">
      <div v-for="(item, index) in topicList" :key="index" @click.stop="_fillTopicInfo(item,index)"
        :class="groupIndexActive===null&&topicActiveIndex===index?'topicActiveStyle topic':
          groupIndexActive!==null&&groupIndexActive==item?.groupIndex&&topicActiveIndex===index&&isActiveStyle?'topicActiveStyle topic':'topicDefaultStyle topic'">
          <div style="padding:16px;" :id="groupIndexActive!==null?`topicLink${item?.groupIndex}${index}`:`topicLink${index+1}`">
            <div class="title">
              {{ index + 1 }}.<span class="type">「{{ getTypeValue(item.questionType) }}」</span
              >{{ item.title }}<span class="grade">（{{ item.score }}分）</span>
            </div>
            <div class="explain">题目说明：{{ item.content }}</div>
            <!-- 题目说明---上传图片 开始-->
            <div v-if="item?.contentUrl!==''&&item?.contentUrl.length!==0&&item?.contentUrl[0]!==''">
              <el-image v-for="(item,index) in item?.contentUrl" :key="index" :src="item" style="margin-right:10px;width:100px;height:100px;" :preview-src-list="[item]"></el-image>
            </div>
            <!-- 题目说明---上传图片--结束 -->
            <!-- 单选题&&判断题 start -->
            <el-radio-group v-model="item.answer" v-if="item.questionType === 'SINGLE_CHOICE_TYPE' || item.questionType ==='JUDGMENT_TYPE'">
              <el-radio
                class="prohibit-click"
                v-for="(ele, index) in item.options"
                :key="index"
                :label="`选项${index + 1}`"
                >{{ ele.optionName }}</el-radio>
            </el-radio-group>
            <!-- 单选题&&判断题 end -->
            <!-- 多选题 start -->
            <el-checkbox-group
              v-model="item.answerCheck"
              v-if="item.questionType == 'MULTIPLE_CHOICE_TYPE'"
            >
              <el-checkbox
                class="prohibit-click"
                v-for="(ele, index) in item.options"
                :key="index"
                :label="`选项${index + 1}`"
                >{{ ele.optionName }}</el-checkbox
              >
            </el-checkbox-group>
            <!-- 多选题 end -->
            <div class="analysis mb16">
              <div v-if="item.questionType !== 'ESSAY_QUESTION_TYPE'">
                <span>正确答案</span> {{ item.answer }}
              </div>
              <div><span>答案解析</span> {{ item.analysis }}</div>
              <!-- 答案解析---上传图片 开始-->
              <div v-if="item.analysisUrl!==''&&item.analysisUrl.length!==0&&item?.analysisUrl[0]!==''">
                <el-image v-for="(item,index) in item.analysisUrl" :key="index" :src="item" style="margin-right:10px; width:100px;height:100px;" :preview-src-list="[item]"></el-image>
              </div>
              <!-- 答案解析---上传图片 结束-->
            </div>

            <div class="fx-row-end handle-btn mb16">
              <!-- <div @click="editOption(item, index)">
                <i class="el-icon-edit"></i>编辑
              </div> -->
              <div @click="_deleteTopicBtn(topicList,item,index)" v-show="!isOpenHistory"><i class="el-icon-delete"></i>删除</div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="renderjs">
import Sortable from 'sortablejs'
export default {
  props: {
    topicList: {
      type: Array,
      default: () => []
    },
    noAllowDrag: {
      type: String,
      default: ''
    },
    groupIndexActive: {
      type: Number,
      default: null
    },
    topicActiveIndexParent: {
      type: Number,
      default: null
    },
    isActiveStyle: {
      type: Boolean,
      default: true
    },
    isOpenHistory: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sortable: '',
      topicActiveIndex: null

    }
  },
  watch: {
    'groupIndexActive' (newVal, oldVal) {
      console.log(newVal, oldVal, 'groupIndexActive')
    },
    'topicActiveIndex' (newVal, oldVal) {
      console.log(newVal, oldVal, 'topicActiveIndex')
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initSortable()
    })
    console.log(this.topicList, 'this.topicList')
  },
  created () {
    this._fillTopicInfo(this.topicList[0], 0)
    console.log(this.topicList, 'topicList9999')
  },
  methods: {
    initSortable () {
      if (this.noAllowDrag === 'noAllowDrag') { // 不允许拖拽
        return
      }
      const that = this
      that.dragData = document.querySelector('.listDrag')
      console.log(that.dragData, 'that.dragData')
      this.sortable = new Sortable(that.dragData, {
        sort: true, // 是否可进行拖拽排序
        animation: 500,
        // 允许被拖拽的类名
        draggable: '.topic',
        // 结束拖拽时触发此函数
        onEnd: (evt) => {
          const returnDate = {
            oldIndex: evt.oldIndex,
            newIndex: evt.newIndex
          }
          console.log(returnDate, 'returnDate')
          this.$emit('changeOptionSort', returnDate)
          // that.changeSort(returnDate)
          // app端需要用这个方法调用另一个script中的方法
          // this.$ownerInstance.callMethod('changeSort', returnDate)
        }
      })
    },
    // 删除
    _deleteTopicBtn (topicList, item, index) {
      if (this.$route.query.paperId) {
        const data = {
          topicList: topicList,
          item: item,
          index: index
        }
        console.log(topicList, item, index, 'topicList,item,index')
        // 试卷--题目编辑---删除
        this.$emit('deleteTopicItem', data)
      } else {
        // 题库--题目新增，编辑--删除
        this.$emit('deleteItem', index)
      }
    },
    // 编辑
    editOption (item, index) {
      this.$emit('editOption', item, index)
    },
    getTypeValue (type) {
      let value
      switch (type) {
        case 'SINGLE_CHOICE_TYPE':
          value = '单项选择'
          break
        case 'MULTIPLE_CHOICE_TYPE':
          value = '多项选择'
          break
        case 'JUDGMENT_TYPE':
          value = '判断题'
          break
        case 'GAP_FILLING_TYPE':
          value = '填空题'
          break
        case 'ESSAY_QUESTION_TYPE':
          value = '问答题'
          break
        default:
          break
      }
      return value
    },
    // 补充题目信息
    _fillTopicInfo (item, index) {
      console.log(item, index, '补充题目信息！')
      item.index = index
      // item.groupIndex = this.groupIndexActive
      this.topicActiveIndex = index
      this.$emit('changeTopicType', JSON.parse(JSON.stringify(item)))
    }
  }
}
</script>

<style lang="scss" scoped>
// .container{padding: 16px;}
.topicDefaultStyle {
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 20px;
}
.topicActiveStyle {
  margin: 12px 0px;
  border: solid 1px transparent;
  border-radius: 4px;
  background-image: linear-gradient(#F0F8FF, #FFFFFF),
  linear-gradient(to bottom, #E7F4FF, #D1EBFF);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.title {
  .type {
    color: #0089ff;
    font-size: 14px;
  }
  color: #000;
  font-size: 16px;
  .grade {
    color: #666;
    font-size: 12px;
  }
}
.explain {
  color: #999;
  font-size: 14px;
  margin: 16px 0;
}
.analysis {
  background: #f6f7f9;
  padding: 10px 12px;
  font-size: 14px;
  span {
    color: #999;
    margin-right: 8px;
    line-height: 25px;
  }
}
.handle-btn {
  font-size: 14px;
  > div {
    margin-left: 24px;
    cursor: pointer;
  }
  i {
    margin-right: 5px;
  }
}
</style>
